.body-line-height {
  line-height: 1.6;
}
.vertical-timeline * {
  box-sizing: border-box;
}
.vertical-timeline {
  width: 95%;
  max-width: 1170px;
  margin: 0 auto;
  position: relative;
  padding: 2em 0;
}
.vertical-timeline::after {
  content: "";
  display: table;
  clear: both;
}
.vertical-timeline::before {
  content: "";
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: #5E5946;
}
@media only screen and (min-width: 1170px) {
  .vertical-timeline.vertical-timeline--two-columns {
    width: 90%;
  }
  .vertical-timeline.vertical-timeline--two-columns:before {
    left: 50%;
    margin-left: -2px;
  }
}
.vertical-timeline-element {
  position: relative;
  margin: 2em 0;
}
.vertical-timeline-element > div {
  min-height: 1px;
}
.vertical-timeline-element:after {
  content: "";
  display: table;
  clear: both;
}
.vertical-timeline-element:first-child {
  margin-top: 0;
}
.vertical-timeline-element:last-child {
  margin-bottom: 0;
}
@media only screen and (min-width: 1170px) {
  .vertical-timeline-element {
    margin: 4em 0;
  }
  .vertical-timeline-element:first-child {
    margin-top: 0;
  }
  .vertical-timeline-element:last-child {
    margin-bottom: 0;
  }
}
.vertical-timeline-element-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 0 4px #00000000, inset 0 2px 0 rgba(0, 0, 0, 0),
    0 3px 0 4px rgba(0, 0, 0, 0);
}
.vertical-timeline-element-icon svg {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -12px;
}
@media only screen and (min-width: 1170px) {
  .vertical-timeline--two-columns .vertical-timeline-element-icon {
    width: 60px;
    height: 60px;
    left: 50%;
    margin-left: -30px;
  }
}
.vertical-timeline-element-icon {
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
}
.vertical-timeline--animate .vertical-timeline-element-icon.is-hidden {
  visibility: hidden;
}
.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
  visibility: visible;
  -webkit-animation: cd-bounce-1 0.6s;
  -moz-animation: cd-bounce-1 0.6s;
  animation: cd-bounce-1 0.6s;
}
@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -moz-transform: scale(1.2);
  }
  100% {
    -moz-transform: scale(1);
  }
}
@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.vertical-timeline-element-content {
  position: relative;
  margin-left: 60px;
  background: #cccccc;
  border-radius: 0.25em;
  padding: 1em;
  box-shadow: 0 3px 0 #ddd;
}
.vertical-timeline-element--no-children .vertical-timeline-element-content {
  background: 0 0;
  box-shadow: none;
}
.vertical-timeline-element-content:after {
  content: "";
  display: table;
  clear: both;
}
.vertical-timeline-element-content h2 {
  color: #303e49;
}
.vertical-timeline-element-content .vertical-timeline-element-date,
.vertical-timeline-element-content p {
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 500;
}
.vertical-timeline-element-content .vertical-timeline-element-date {
  display: inline-block;
}
.vertical-timeline-element-content p {
  margin: 1em 0 0;
  line-height: 1.6;
}
.vertical-timeline-element-title {
  padding-bottom: 1px;
}
.vertical-timeline-element-subtitle {
  margin: 0;
}
.vertical-timeline-element-content .vertical-timeline-element-date {
  float: left;
  padding: 0.8em 0;
  opacity: 0.9;
}
.vertical-timeline-element-content-arrow {
  content: "";
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid #cccccc;
}
.vertical-timeline-element--no-children
  .vertical-timeline-element-content-arrow {
  display: none;
}
@media only screen and (min-width: 768px) {
  .vertical-timeline-element-content h2 {
    font-size: 20px;
    font-size: 1.25rem;
  }
  .vertical-timeline-element-content p {
    font-size: 16px;
    font-size: 1rem;
  }
  .vertical-timeline-element-content .vertical-timeline-element-date {
    font-size: 14px;
    font-size: 0.875rem;
    color: #5E5946;
  }
}
@media only screen and (min-width: 1170px) {
  .vertical-timeline--two-columns .vertical-timeline-element-content {
    margin-left: 0;
    padding: 1.5em;
    width: 44%;
  }
  .vertical-timeline--two-columns .vertical-timeline-element-content-arrow {
    top: 24px;
    left: 100%;
    transform: rotate(180deg);
  }
  .vertical-timeline--two-columns
    .vertical-timeline-element-content
    .vertical-timeline-element-date {
    position: absolute;
    width: 100%;
    left: 124%;
    top: 6px;
    font-size: 16px;
    font-size: 1rem;
  }
  .vertical-timeline--two-columns
    .vertical-timeline-element.vertical-timeline-element--right
    .vertical-timeline-element-content,
  .vertical-timeline--two-columns
    .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left)
    .vertical-timeline-element-content {
    float: right;
  }
  .vertical-timeline--two-columns
    .vertical-timeline-element.vertical-timeline-element--right
    .vertical-timeline-element-content-arrow,
  .vertical-timeline--two-columns
    .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left)
    .vertical-timeline-element-content-arrow {
    top: 24px;
    left: auto;
    right: 100%;
    transform: rotate(0);
  }
  .vertical-timeline--two-columns
    .vertical-timeline-element.vertical-timeline-element--right
    .vertical-timeline-element-content
    .vertical-timeline-element-date,
  .vertical-timeline--two-columns
    .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left)
    .vertical-timeline-element-content
    .vertical-timeline-element-date {
    left: auto;
    right: 124%;
    text-align: right;
  }
}
.vertical-timeline--animate .vertical-timeline-element-content.is-hidden {
  visibility: hidden;
}
.vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
  visibility: visible;
  -webkit-animation: cd-bounce-2 0.6s;
  -moz-animation: cd-bounce-2 0.6s;
  animation: cd-bounce-2 0.6s;
}
@media only screen and (min-width: 1170px) {
  .vertical-timeline--two-columns.vertical-timeline--animate
    .vertical-timeline-element.vertical-timeline-element--right
    .vertical-timeline-element-content.bounce-in,
  .vertical-timeline--two-columns.vertical-timeline--animate
    .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left)
    .vertical-timeline-element-content.bounce-in {
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    -moz-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
  }
}
@media only screen and (max-width: 1169px) {
  .vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    -moz-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
  }
}
@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(20px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -moz-transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(-20px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #AE887B !important;
  /* background-color: #EAE2DC;  */
  background-color: #F6F1EC; 
  /* background: #D9BFB1; */
  border-color: #AE887B7F #AE887B7F #F6F1EC;
}

.display-4 {
  font-weight: 400;
}

.increase-weight {
  font-weight: 350;
}

.increase-weight-400 {
  font-weight: 400;
}

.element-2-color{
  color: #AE887B
}

a.skills-tab{
  color:#5E5946;
  font-weight: 400;
}

a.skills-tab:hover{
  /* F6F1EC */
  background-color:#E9E4DF;
}

.card-link + .card-link{
  margin-left:0;
}

.card-link {
  margin-right:20px;
}

.card-text {
  font-size: 1.2rem;
}

.element1 {
  color:#5E5946;
  background-color: #D9BFB1;
}

.element2 {
  color:#5E5946;
  background-color: #F0DED0;
}

.badge {
  font-size: 100%;
  font-weight: 500
}

.footer-badge {
  font-size: 80%;
  font-weight: 500
}


a.element1:hover {
  color:#5E5946;
  background-color: #E2C8BA;
}

a.element1:active {
  color:#5E5946;
  /* D9BFB1 */
  background-color: #E9CFC1;
}

a.element2:hover {
  color:#5E5946;
  background-color: #F9E7D9;
}

a.element2:active {
  color:#5E5946;
  background-color: #FFEEE0;
}

.color-element1 {
  background-color: #F0DED0;
}

.color-element2 {
  background-color: #D9BFB1;
}

.center-div-native-about {
  position: relative;
  transform: translateY(30%);
}

.center-div-native-skills {
  position: relative;
  transform: translateY(100px);
}

.text-light {
  color: #F6F1EC !important;
}

.element-font-color {
  color: #5E5946
}

.text-light:hover {
  color: #F6F1EC !important;
}

a.footer {
  color:#AE887B;
}

.footer-object {
  color: #AE887B
}

.footer-badge {
  color: #F5F5F5;
  background-color: #AE887B
}

a.footer-badge:hover {
  color: #F5F5F5;
  background-color: #B79184
}

a.footer-badge:active {
  color: #F5F5F5;
  background-color: #BE988B
}

.hero-shadow {
  text-shadow: 
  -1px 1px 1px #5E5946ff,
	1px 1px 1px #5E5946ff,
	1px -1px 1px #5E5946ff,
	-1px -1px 1px #5E5946ff;
}

.hero-shadow:hover {
  text-shadow: 
  -1px 1px 0px #5E594600,
	1px 1px 0px #5E594600,
	1px -1px 0px #5E594600,
	-1px -1px 0px #5E594600;
}

.btn-outline-light {
  color: #F6F1EC;
  border-color: #F6F1EC;
}

.btn-outline-light:hover {
  color: #5E5946;
  background-color: #F6F1EC;
  border-color: #5E5946;
}

.btn-outline-light:active {
  color: #5E5946 !important;
  background-color: #F6F1EC !important;
  border-color: #F6F1EC !important;
}

.btn-outline-dark {
  color: #5E5946;
  border-color: #5E5946;
}

.btn-outline-dark:hover {
  color: #5E5946;
  background-color: #C3C1AB;
  border-color: #5E5946;
}

.btn-outline-dark:active {
  color: #5E5946 !important;
  background-color: #C3C1AB !important;
  border-color: #C3C1AB !important;
}

.btn-outline-dark:active {
  color: #5E5946;
  background-color: #C3C1AB;
  border-color: #5E5946;
}

.btn-outline-secondary1, .btn-outline-secondary2 {
  color: #5E5946;
  border-color: #5E5946;
}

.btn-outline-secondary1:hover {
  color: #5E5946;
  background-color: #D9BFB1;
  border-color: #5E5946;
}

.btn-outline-secondary2:hover {
  color: #5E5946;
  background-color: #F0DED0;
  border-color: #5E5946;
}

.btn-outline-secondary1:active {
  color: #5E5946 !important;
  background-color: #D9BFB1 !important;
  border-color: #D9BFB1 !important;
}

.btn-outline-secondary2:active {
  color: #5E5946 !important;
  background-color: #F0DED0 !important;
  border-color: #F0DED0 !important;
}

.btn:focus {
  box-shadow: 0 0 0 0 rgb(108 117 125 / 0%);
}

.btn:focus-visible {
  box-shadow: 0 0 0 0 rgb(108 117 125 / 0%);
}

.btn:active {
  box-shadow: 0 0 0 0 rgb(108 117 125 / 0%);
}

.btn:active:focus {
  box-shadow: 0 0 0 0 rgb(108 117 125 / 0%) !important;
}